
import { ArticleModel } from '@/model/articleModel'
import { defineComponent, PropType } from 'vue'
import { VueDraggableNext } from 'vue-draggable-next'
export default defineComponent({
  components: {
    draggable: VueDraggableNext
  },
  props: {
    list1: {
      type: [] as PropType<Array<ArticleModel>>,
      default: () => {
        return []
      }
    },
    list2: {
      type: [] as PropType<Array<ArticleModel>>,
      default: () => {
        return []
      }
    },
    list1Title: {
      type: String,
      default: 'list1'
    },
    list2Title: {
      type: String,
      default: 'list2'
    },
    list1width: {
      type: String,
      default: '48%'
    },
    list2width: {
      type: String,
      default: '48%'
    }
  },
  setup(props) {
    const isNotInList1 = (v: ArticleModel) => {
      return props.list1.every((k: any) => v.id !== k.id)
    }
    const isNotInList2 = (v: ArticleModel) => {
      return props.list2.every((k: any) => v.id !== k.id)
    }
    const deleteEle = (ele: ArticleModel) => {
      for (const item of props.list1) {
        if (item.id === ele.id) {
          const index = props.list1.indexOf(item)
          // eslint-disable-next-line vue/no-mutating-props
          props.list1.splice(index, 1)
          break
        }
      }
      if (isNotInList2(ele)) {
        // eslint-disable-next-line vue/no-mutating-props
        props.list2.unshift(ele)
      }
    }
    const pushEle = (ele: ArticleModel) => {
      for (const item of props.list2) {
        if (item.id === ele.id) {
          const index = props.list2.indexOf(item)
          // eslint-disable-next-line vue/no-mutating-props
          props.list2.splice(index, 1)
          break
        }
      }
      if (isNotInList1(ele)) {
        // eslint-disable-next-line vue/no-mutating-props
        props.list1.push(ele)
      }
    }
    return {
      deleteEle,
      pushEle,
      isNotInList1,
      isNotInList2
    }
  }
})

